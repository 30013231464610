import React, { useEffect, useMemo, useState } from "react";

import { Control, Controller, DeepMap, FieldError, Validate } from "react-hook-form";
import { Dropdown, DropdownItemProps, Input, Label } from "semantic-ui-react";
import { parsePhoneNumber, getExample, ParsedPhoneNumber as ParsedPhoneNumberType } from "awesome-phonenumber";
import { CountryRegionCodes } from "../../../constants/CountryRegionCodes";
import classNames from "classnames";
import { get } from "lodash";
import { getFlagEmoji } from "../GetFlagEmoji";

type Props<FormValue, Key extends string> = {
  name: Key;
  control: Control<Record<Key, FormValue>>;
  defaultValue?: FormValue;
  label?: string;
  subLabel?: string;
  underText?: string;
  placeholder?: string | null;
  defaultRegionCode?: string;
  errors?: DeepMap<Record<Key, FormValue>, FieldError>;
  readonly?: boolean;
  disabled?: boolean;
  fluid?: boolean;
  autoComplete?: string;
  rules?: Record<string, Validate>;
  strict?: boolean;
  required?: boolean;
  inputRef?: React.RefObject<Input>;
  transform?: { input: (value: FormValue) => string; output: (value: string) => FormValue };
  onBlur?: () => void;
};

export function ControlledPhoneNumber<FormValue, Key extends string>(props: Props<FormValue, Key>) {
  const {
    name,
    control,
    defaultValue,
    label,
    subLabel,
    underText,
    placeholder,
    defaultRegionCode = "US",
    errors,
    readonly = false,
    disabled = false,
    fluid = false,
    autoComplete,
    rules,
    strict = true,
    required = false,
    inputRef,
    transform = { input: (value) => String(value ?? ""), output: (value) => value },
    onBlur = () => {},
  } = props;
  const [selectedRegionCode, setSelectedRegionCode] = useState(defaultRegionCode);

  const error = get(errors, name, null) as FieldError | null;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        validate: {
          _required: (value) => {
            if (required && !transform.input(value)) return "This field is required";
            return true;
          },
          _length: (value) => {
            const maxLength = 40;

            if (!transform.input(value)) return true;
            if (transform.input(value).length > maxLength) return `Phone number is longer than ${maxLength} characters`;

            return;
          },
          _strict: (value) => {
            if (!transform.input(value)) return true;
            if (!strict) return true;

            const parsed = parsePhoneNumber(transform.input(value), { regionCode: selectedRegionCode });

            const valueIsValid = parsed.valid || parsed.shortValid;
            if (!valueIsValid) return "Invalid phone number";

            return true;
          },
          ...rules,
        },
      }}
      render={({ value, onChange }) => {
        const [internalValue, setInternalValue] = useState<string>(transform.input(value));
        const [inputIsFocused, setInputIsFocused] = useState(false);
        const [expandSelectedRegionCode, setExpandSelectedRegionCode] = useState(false);
        const [showRegionCodeDropdown, setShowRegionCodeDropdown] = useState(false);
        const [overrideAutoSelectedRegionCode, setOverrideAutoSelectedRegionCode] = useState(false);

        const parsedInternalValue = parsePhoneNumber(internalValue, { regionCode: selectedRegionCode });
        const formattedInternalValue = getFormattedPhoneNumber(
          parsedInternalValue,
          selectedRegionCode,
          defaultRegionCode,
        );
        const parsedValue = parsePhoneNumber(transform.input(value), { regionCode: selectedRegionCode });
        const formattedValue = getFormattedPhoneNumber(parsedValue, selectedRegionCode, defaultRegionCode);

        // When focused, always display exactly what the user typed originally
        // When blurred, display the parsed number or fallback to the original value if parsing fails
        const valueToDisplay = inputIsFocused ? internalValue : formattedValue || internalValue;

        const countryOptions: DropdownItemProps[] = useMemo(() => {
          return CountryRegionCodes.map(({ callingCode, regionCode, countryName }) => {
            return {
              key: regionCode,
              text: (
                <div className='tw-flex tw-items-center'>
                  <div className='tw-mr-2'>{regionCode}</div>
                  <div>{getFlagEmoji(regionCode)}</div>
                </div>
              ),
              value: regionCode,
              content: (
                <div className='tw-flex tw-items-center tw-justify-between'>
                  <div className='tw-flex tw-items-center'>
                    <div className='tw-mr-2'>{getFlagEmoji(regionCode)}</div>
                    <div>{countryName}</div>
                  </div>
                  <div className='tw-text-grey'>+{callingCode}</div>
                </div>
              ),
              className: "tw-w-64",
              meta: {
                sortKey: countryName,
                searchTerms: [countryName, regionCode, callingCode.toString(), `+${callingCode}`],
              },
            };
          }).sort((a, b) => {
            // Make sure default region code is always on top
            if (a.value === defaultRegionCode) {
              return -1;
            }
            if (b.value === defaultRegionCode) {
              return 1;
            }
            return a.meta.sortKey.localeCompare(b.meta.sortKey);
          }); // Make sure united states is always on top, otherwise sort alphabetically
        }, []);

        // Update the value in the form when the internal values change
        useEffect(() => {
          // Update the selected region code if the user types a "+" and the region code is different
          if (
            internalValue.startsWith("+") &&
            parsedInternalValue.regionCode !== selectedRegionCode &&
            !overrideAutoSelectedRegionCode
          ) {
            setSelectedRegionCode(parsedInternalValue.regionCode ?? defaultRegionCode);
            setShowRegionCodeDropdown(true);
          }

          // Update the form value if the internal value changes
          const newValue = formattedInternalValue || internalValue;
          if (value !== newValue) {
            onChange(transform.output(newValue));
          }
        }, [internalValue]);

        // This is for handling changes coming from external sources (like a form default, setValue, or reset)
        useEffect(() => {
          // Make sure we never run this code if the user is currently typing
          if (inputIsFocused) return;

          // If the form is being reset, we should reset all the other state as well
          if (!value) {
            setExpandSelectedRegionCode(false);
            setShowRegionCodeDropdown(false);
            setSelectedRegionCode(defaultRegionCode);
          }

          // Update/show region code selector if the value has a region code
          const possibleRegionCode = tryExtractRegionCode(value);

          const shouldUpdateSelectedRegionCode = !!possibleRegionCode && possibleRegionCode !== selectedRegionCode;
          if (shouldUpdateSelectedRegionCode) setSelectedRegionCode(tryExtractRegionCode(value) ?? defaultRegionCode);

          const shouldSetShowRegionCodeDropdown = !!possibleRegionCode && possibleRegionCode !== defaultRegionCode;
          if (shouldSetShowRegionCodeDropdown) setShowRegionCodeDropdown(true);

          // Finally, update the internal value with the new value from the form
          const bothAreEmpty = !parsedValue.number?.international && !parsedInternalValue.number?.international;
          const shouldUpdateInternalValue =
            parsedValue.number?.international !== parsedInternalValue.number?.international || bothAreEmpty;
          if (shouldUpdateInternalValue) {
            setInternalValue(transform.input(value));
          }
        }, [value]);

        const tryCorrectRegionCode = () => {
          if (overrideAutoSelectedRegionCode) return;

          if (parsedInternalValue.valid && parsedInternalValue.regionCode === selectedRegionCode) return;
          if (parsedInternalValue.valid && parsedInternalValue.regionCode !== selectedRegionCode) {
            setSelectedRegionCode(parsedInternalValue.regionCode);
            return;
          }

          const possibleRegionCode = tryExtractRegionCode(internalValue);
          if (possibleRegionCode) {
            setShowRegionCodeDropdown(true);
            setSelectedRegionCode(possibleRegionCode);
          }
        };

        const getPlaceholder = () => {
          if (placeholder == null || placeholder === "") return placeholder;
          if (selectedRegionCode === defaultRegionCode) return getExample(selectedRegionCode).number?.national;
          return getExample(selectedRegionCode).number?.international;
        };

        return (
          <>
            {label && <label htmlFor={name}>{label}</label>}
            {subLabel && <div className='-tw-mt-1 tw-mb-1 tw-text-sm tw-text-grey'>{subLabel}</div>}
            {readonly && <ParsedPhoneNumber value={value} defaultRegionCode={defaultRegionCode} />}
            {!readonly && (
              <div className='tw-flex'>
                <Input
                  ref={inputRef}
                  value={valueToDisplay ?? ""}
                  onFocus={() => {
                    setInputIsFocused(true);
                  }}
                  onBlur={() => {
                    setInputIsFocused(false);
                    tryCorrectRegionCode();
                    onBlur();
                  }}
                  onChange={(_, { value }) => {
                    setInternalValue(value);
                    setOverrideAutoSelectedRegionCode(false);
                  }}
                  disabled={disabled}
                  placeholder={getPlaceholder()}
                  autoComplete={autoComplete}
                  fluid={fluid}
                  labelPosition={showRegionCodeDropdown ? "right" : undefined}
                  label={
                    showRegionCodeDropdown && (
                      <Dropdown
                        className={classNames({ "tw-w-48": expandSelectedRegionCode })}
                        value={selectedRegionCode}
                        onChange={(_, data) => {
                          setInternalValue((prev) => {
                            return stripRegionCodeFromPhoneNumber(prev);
                          });
                          setSelectedRegionCode(data.value as string);
                          setExpandSelectedRegionCode(false);
                          if (internalValue) setOverrideAutoSelectedRegionCode(true);
                        }}
                        onBlur={() => setExpandSelectedRegionCode(false)}
                        disabled={disabled}
                        compact
                        scrolling
                        search={(options, query) => {
                          return options.filter(({ meta }) =>
                            meta.searchTerms.some((term: string) =>
                              term.trim().toLocaleLowerCase().includes(query.trim().toLocaleLowerCase()),
                            ),
                          );
                        }}
                        searchInput={{
                          className: classNames({
                            "tw-w-48": expandSelectedRegionCode,
                            "tw-w-20": !expandSelectedRegionCode,
                          }),
                        }}
                        direction='left'
                        onSearchChange={() => setExpandSelectedRegionCode(true)}
                        selection
                        options={countryOptions}
                        selectOnNavigation={false}
                      />
                    )
                  }
                />
              </div>
            )}
            {underText && <div className='tw-mt-1 tw-text-sm tw-text-grey'>{underText}</div>}
            {!!error && (
              <Label basic prompt pointing='above' aria-live='polite' id={`${props.name}-error`}>
                {error?.message}
              </Label>
            )}
          </>
        );
      }}
    />
  );
}

export function ParsedPhoneNumber({ value, defaultRegionCode = "US" }: { value: string; defaultRegionCode?: string }) {
  const valueToDisplay = tryParsePhoneNumber(value, defaultRegionCode) ?? "N/A";
  const shouldBeGray = !valueToDisplay;

  return <span className={classNames({ "tw-text-grey": shouldBeGray })}>{valueToDisplay}</span>;
}

export function tryParsePhoneNumber(value: string, defaultRegionCode = "US") {
  const getValueToDisplay = () => {
    if (!value) return ["", null];

    const possibleRegionCode = tryExtractRegionCode(value);

    const parsed = parsePhoneNumber(value, { regionCode: possibleRegionCode ?? defaultRegionCode });
    const regionIsDefault = parsed.regionCode === defaultRegionCode;

    if (!parsed.possible) return [value, null];
    if (regionIsDefault) return [parsed.number?.national, null];
    if (parsed.regionCode) return [parsed.number?.international, getFlagEmoji(parsed.regionCode)];
    return [parsed.number?.international, null];
  };

  const [valueToDisplay, flagEmoji] = getValueToDisplay();

  if (!valueToDisplay) return null;
  if (!flagEmoji) return `${valueToDisplay}`.trim();

  return `${valueToDisplay} ${flagEmoji}`.trim();
}

function tryExtractRegionCode(value: string) {
  const parsedRaw = parsePhoneNumber(value);
  const parsedWithPlus = parsePhoneNumber(`+${value}`);

  const regionCode = [
    parsedRaw.valid ? parsedRaw.regionCode : null,
    parsedWithPlus.valid ? parsedWithPlus.regionCode : null,
  ]
    .filter((regionCode) => regionCode)
    .at(0);

  return regionCode ?? null;
}

function stripRegionCodeFromPhoneNumber(value: string) {
  const parsed = parsePhoneNumber(value);
  if (parsed.valid) return parsed.number?.significant;
  return value;
}

function getFormattedPhoneNumber(
  parsedPhoneNumber: ParsedPhoneNumberType,
  selectedRegionCode: string,
  defaultRegionCode: string,
) {
  if (selectedRegionCode === defaultRegionCode) return parsedPhoneNumber.number?.national;
  return parsedPhoneNumber.number?.international;
}
